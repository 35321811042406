import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Projects extends Vue {
  public projectSwiper

  public swiperOption = {
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
      renderBullet(index, className) {
        const images = require.context('../../assets/img/', false, /\.png$/);
        const imgUrl = images(`./pagination${index + 1}-icon.png`);
        return `<span class="${className}"><img src=${imgUrl}></span>`;
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  }
}
