import { Component, Prop, Vue } from 'vue-property-decorator';

const items = [
  {
    id: 5,
    title: 'Построение инфраструктуры и поиск идей',
    isActive: true,
  }, {
    id: 1,
    title: 'Диагностика',
    isActive: false,
  }, {
    id: 2,
    title: 'Обработка данных',
    isActive: false,
  }, {
    id: 3,
    title: 'Внедрение',
    isActive: false,
  }, {
    id: 4,
    title: 'Сопровождение',
    isActive: false,
  },
];

@Component({
})
export default class Approach extends Vue {
  public activeTab = 0

  public items = items

  selectTab(index: number) {
    if (this.activeTab === index) return;
    this.items[this.activeTab].isActive = false;
    this.activeTab = index;
    this.items[this.activeTab].isActive = true;
  }
}
