import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HomePageBaner extends Vue {
  @Prop() readonly title!: {
    text: string,
    span: string,
  }

  @Prop() readonly textTop!: string

  @Prop() readonly textBottom!: string

  @Prop() readonly logo!: boolean

  // titleLocal() {
  //   return this.title;
  // }

  // textLocal = this.text
  // computedTitle() {
  //   return this.title;
  // }

  // computedText() {
  //   return this.text;
  // }
}
