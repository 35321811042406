






















import { Component, Vue } from 'vue-property-decorator';
import HomePageBaner from '@/components/HomePageBaner/index.vue';// @ is an alias to /src
import Advantages from '@/components/Advantages/index.vue';
import Projects from '@/components/Projects/index.vue';
import Approach from '@/components/Approach/index.vue';
import Team from '@/components/Team/index.vue';
import Why from '@/components/Why/index.vue';
import AboutUs from '@/components/AboutUs/index.vue';
import ContactUs from '@/components/ContactUs/index.vue';
import InDevelopment from '@/components/InDevelopment/index.vue';

@Component({
  components: {
    HomePageBaner,
    AboutUs,
    Advantages,
    Projects,
    Approach,
    Team,
    Why,
    ContactUs,
    InDevelopment,
  },
})
export default class Home extends Vue {
  public banerTextTop = 'Ваш надежный проводник в цифровую эпоху';

  public banerLogo = true;

  public banerTextBottom = 'Аналитика нового поколения для решения задач вашей организации';

  // public banerTitle = {
  //   text: '',
  //   span: 'NAURA',
  // }
}
