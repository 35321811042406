import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Team extends Vue {
  public teamSwiper

  public swiperOption = {
    lidesPerView: 1,
    breakpointsInverse: true,
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      960: {
        slidesPerView: 2,
      },
      1264: {
        slidesPerView: 3,
      },
      1904: {
        slidesPerView: 4,
      },
    },
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }
}
